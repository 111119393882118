<script>
import { required, email } from "vuelidate/lib/validators"
import { register } from "../../../services/user.service"

export default {
  data() {
    return {
      user: { 
        username: "", 
        email: "", 
        password: "",
        fullname: "",
        tax_id: "",
        phone: "",
        address: "",
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations: {
    user: {
      username: { required },
      email: { required, email },
      password: { required },
      fullname: { required },
      tax_id: { required },
      phone: { required },
      address: { required }
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    async register() {
      this.regError = ""
      this.isRegisterError = false
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      const result = await register(this.user)

      if (result.status >= 400) {
        if (result.status === 400) {
          const errors = Object.values(result.errors)
          errors.forEach(error => this.regError += (this.regError === null) ? '': `${error[0]} <br/>` )
        } else {
          this.regError = result.errors.status
        }
        
        this.isRegisterError = true
      } else {
        this.regError = ""
        this.isRegisterError = false

        this.tryingToRegister = false
        this.isRegisterError = false
        this.registerSuccess = true

        // TODO: change a thankyou page
        this.$router.push(
          this.$route.query.redirectFrom || { name: "login" }
        )
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
          </div>
          <div class="col-lg-4">
            <div class="p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-dark.png" height="90" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Registrar Cuenta</h4>
                        <p class="text-muted">Para iniciar el proceso de creación de cuenta llene el siguiente formulario.</p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="registerSuccess"
                          class="mt-3"
                          variant="success"
                          dismissible
                        >Usuario Registrado</b-alert>

                        <b-alert
                          v-model="isRegisterError"
                          class="mt-3"
                          variant="danger"
                          dismissible
                        ><span v-html="regError"></span></b-alert>

                        <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                        >{{notification.message}}</b-alert>
                        
                        <form class="form-horizontal" @submit.prevent="register">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-user-2-line auti-custom-input-icon"></i>
                            <label for="username">Nombre de usuario</label>
                            <input
                              v-model="user.username"
                              type="text"
                              class="form-control"
                              id="username"
                              :class="{ 'is-invalid': submitted && $v.user.username.$error }"
                              placeholder="Ingrese un nombre de usuario"
                            />
                            <div
                              v-if="submitted && !$v.user.username.required"
                              class="invalid-feedback"
                            >Username is required.</div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">Correo Eléctronico</label>
                            <input
                              v-model="user.email"
                              type="email"
                              class="form-control"
                              id="useremail"
                              placeholder="Ingrese un correo eléctronico"
                              :class="{ 'is-invalid': submitted && $v.user.email.$error }"
                            />
                            <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                              <span v-if="!$v.user.email.required">El Correo eléctronico es requerido.</span>
                              <span v-if="!$v.user.email.email">El correo eléctronico ingresado es invalido.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Contraseña</label>
                            <input
                              v-model="user.password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Ingrese una contraseña"
                              :class="{ 'is-invalid': submitted && $v.user.password.$error }"
                            />
                            <div
                              v-if="submitted && !$v.user.password.required"
                              class="invalid-feedback"
                            >La contraseña es requerida.</div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-shield-user-line auti-custom-input-icon"></i>
                            <label for="fullname">Nombre Completo</label>
                            <input
                              v-model="user.fullname"
                              type="text"
                              class="form-control"
                              id="fullname"
                              placeholder="Ingrese el nombre del administrador, o razón social"
                              :class="{ 'is-invalid': submitted && $v.user.fullname.$error }"
                            />
                            <div
                              v-if="submitted && !$v.user.fullname.required"
                              class="invalid-feedback"
                            >El nombre completo es requerido.</div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-book-mark-line auti-custom-input-icon"></i>
                            <label for="tax_id">NIT</label>
                            <input
                              v-model="user.tax_id"
                              type="text"
                              class="form-control"
                              id="tax_id"
                              placeholder="Ingrese el número de NIT"
                              :class="{ 'is-invalid': submitted && $v.user.tax_id.$error }"
                            />
                            <div
                              v-if="submitted && !$v.user.tax_id.required"
                              class="invalid-feedback"
                            >El número de NIT es requerido.</div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-phone-line auti-custom-input-icon"></i>
                            <label for="phone">Phone</label>
                            <input
                              v-model="user.phone"
                              type="text"
                              class="form-control"
                              id="phone"
                              placeholder="Ingrese el número de teléfono"
                              :class="{ 'is-invalid': submitted && $v.user.phone.$error }"
                            />
                            <div
                              v-if="submitted && !$v.user.phone.required"
                              class="invalid-feedback"
                            >El número de teléfono es requerido.</div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-map-pin-line auti-custom-input-icon"></i>
                            <label for="address">Dirección</label>
                            <input
                              v-model="user.address"
                              type="text"
                              class="form-control"
                              id="address"
                              placeholder="Ingrese una dirección"
                              :class="{ 'is-invalid': submitted && $v.user.address.$error }"
                            />
                            <div
                              v-if="submitted && !$v.user.address.required"
                              class="invalid-feedback"
                            >La dirección es requerida.</div>
                          </div>

                          <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >Registrarse</button>
                          </div>

                          <div class="mt-4 text-center">
                            <p class="mb-0">
                              Después de registrarse un representante se pondrá en contacto con usted.
                            </p>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Ya tiene una cuenta?
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                          >Inicie sesión</router-link>
                        </p>
                        <p>
                          © 2022 MaxParking.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>